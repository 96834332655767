import React, { useState } from 'react';
// TODO replace const { instance } = useMsal(); to import { msalInstance } from '../config/MsalConfig';
import { useMsal } from "@azure/msal-react";
import { authentication } from '@microsoft/teams-js';
import { useTeams } from 'msteams-react-base-component';
import { Alert } from 'react-bootstrap';
import "./SignIn.scss"
import { logToDatadog } from '../../index';
import { InteractionRequiredAuthError } from "@azure/msal-browser";

const SignIn = () => {
  const supportEmail = "support@solvti.com";
  const { instance } = useMsal();
  const [{ inTeams }] = useTeams();
  const [errorRequest, setErrorRequest] = useState("");
  const MAIN_WEBSITE_URL = process.env.REACT_APP_MAIN_WEBSITE_URL || "https://employee.oms-pruefservice.de";
  const IS_RETAIL = MAIN_WEBSITE_URL.includes('retail');
  const CONSENT_URL = `${MAIN_WEBSITE_URL}/auth-consent`;
  const SCOPE = [`${process.env.REACT_APP_AZURE_CLIENT_ID}/.default`];

  instance.handleRedirectPromise();

  const handleSignIn = async () => {
    const activeAccount = instance.getActiveAccount();
    const authenticationOptions = activeAccount ? { account: activeAccount } : {};

    try {
      if (isMobileDevice()) {
        logToDatadog("SignIn: User logging from Mobile device");
      } else {
        logToDatadog("SignIn: User logging from Desktop device");
      }
      if (inTeams) {
        await authentication.authenticate({ url: CONSENT_URL });
        logToDatadog("SignIn: User logging in TeamsApp");
      }
      // Try to login silentSSO first.
      const silentSSOOptions = { scopes: SCOPE, loginHint: "", ...authenticationOptions };
      const ssoResponse = await instance.ssoSilent(silentSSOOptions);
      instance.setActiveAccount(ssoResponse.account);
      logToDatadog("SignIn: User logged via ssoSilent method");
    } catch (silentSSOError) {
      // Needs to provide credentials again
      if (silentSSOError instanceof InteractionRequiredAuthError) {
        if (inTeams) {
          await handleTeamsLogin(authenticationOptions);
        } else {
            await handleRedirectBrowserLogin(authenticationOptions);
        }
      } else {
        logToDatadog(`SignIn: Error method silentSSO: ${silentSSOError.message}`, "error");
        setErrorRequest(`Authentication failed: ${silentSSOError.message}`);
      }
    }
  };

  const handleTeamsLogin = async (authenticationOptions) => {
    try {
        const loginPopupOptions = { scopes: SCOPE, ...authenticationOptions };
        const loginPopupResponse = await instance.loginPopup(loginPopupOptions);
        instance.setActiveAccount(loginPopupResponse.account);
        logToDatadog("SignIn: User logged via loginPopup method");
    } catch (popUpLoginError) {
        handlePopupLoginError(popUpLoginError);
    }
  };

  const handlePopupLoginError = (popUpLoginError) => {
      if (popUpLoginError.message.includes('popup_window_error')) {
        if (!isMobileDevice()) {
          logToDatadog(`SignIn: Error method loginPopup: ${popUpLoginError.message}`, "error");
          setErrorRequest("Please enable pop-ups in your browser settings for this website and try again.");
        }
      } else {
        logToDatadog(`SignIn: Error method loginPopup: ${popUpLoginError.message}`, "error");
        setErrorRequest(`loginPopup failed: ${popUpLoginError.message}`);
      }
  };

  const handleRedirectBrowserLogin = async (authenticationOptions) => {
    try {
        const loginRedirectOptions = { scopes: SCOPE, redirectUri: window.location.origin, ...authenticationOptions };
        const loginRedirectResponse = await instance.loginRedirect(loginRedirectOptions);
        instance.setActiveAccount(loginRedirectResponse.account);
        logToDatadog("SignIn: User logged via loginRedirect method");
    } catch (loginRedirectError) {
        logToDatadog(`SignIn: Error method loginRedirect: ${loginRedirectError.message}`, "error");
        setErrorRequest(`loginRedirect failed: ${loginRedirectError.message}`);
    }
};

  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const logoCompanySrc = process.env.PUBLIC_URL + (IS_RETAIL 
    ? "/images/oms_retail_logo_red_blue_rgb.png" 
    : "/images/icon-labora-holding.svg");

  return (
  <div className="signin-form">
    {errorRequest && <Alert variant="danger" className="m-0 w-100"><b>{errorRequest}</b></Alert>}

    <img 
      src={logoCompanySrc} 
      alt="Company Logo" height={90}
      style={{ margin: "3.8rem"}} 
    />
    
    <button className="btn btn-ms" onClick={handleSignIn}>
      <img src={process.env.PUBLIC_URL + "/images/icon-microsoft.svg"} alt="Microsoft logo" />
      <span className="text-ms">Log in with Microsoft</span>
    </button>

    <a className="email-support" href={`mailto:${supportEmail}`}> Email Support </a>
  </div>
);
}

export default SignIn